@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Nanum+Pen+Script&display=swap');


:root {
  --blue-text-color: #3764B1
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.page_section {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(45deg, #E0CAE2, #F7AFAF);
}

.container {
  margin: 0 auto;
}

.first_page_wrapper {

  .logo {
    text-align: center;
    padding: 3rem 0;

    .subtitle {
      font-family: 'Dancing Script', cursive;
      color: white;

      h1 {
        font-size: 64px;
      }
    }
  }

  .store_img_wrapper {
    img {
      width: 100%;
    }
  }


  .store_time_box {
    background-color: var(--blue-text-color);
    text-align: center;
    padding: 1rem 0;
    color: white;
  }

  .address_box {
    font-family: 'Nanum Pen Script', cursive;
    margin-top: 35px;
    text-align: center;
    color: var(--blue-text-color);
    font-size: 25px;
    padding-bottom: 35px;
    h1 {
      margin: 0;
    }
  }

  .sns_button_list_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 3rem;

    button {
      cursor: pointer;
      margin-left: 1.2rem;
      background-color: #62545C;
      border: none;
      border-radius: 50%;
      width: 120px;
      height: 120px;

      img {
        width: 70px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

}

.second_page_wrapper {
  .logo {
    text-align: center;
    position: relative;
    padding-top: 100px;

    .blue_box {
      width: 90%;
      height: 130px;
      background-color: #3764B1;
      border-radius: 50px;
      position: absolute;
      left: 0;
      z-index: 8;
    }

    .yellow_box {
      width: 90%;
      height: 130px;
      background-color: #FECD37;
      border-radius: 50px;
      position: absolute;
      right: 0;
      top: 230px;
      z-index: 8;
    }

    img {
      position: absolute;
      z-index: 10;
      left: 50%;
      top: calc(50% + 170px);
      transform: translate(-50%, -50%);
    }
  }
}

.page_title {
  color: #FFF3C8;
  text-align: center;

  .subtitle {
    margin-top: 35px;
    color: white;

    p {
      display: block;
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0.3rem 0;
    }
  }

  h1 {
    font-size: 2.6rem;

    span {
      font-size: 4.8rem;
    }
  }
}


.introduce_cat_list_wrapper {
  padding-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;

  .cat_introduce_box {
    margin-bottom: 2rem;
    width: 30%;
    flex: 0 0 30%;
    padding: 1rem;
    text-align: center;

    .cat_species_eng {
      font-family: 'Dancing Script', cursive;
    }

    .cat_species_kor {
      font-family: 'Nanum Pen Script', cursive;
    }

    img {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0.5rem 0;
      text-align: center;
      color: var(--blue-text-color);
      font-size: 35px;
      font-weight: bold;
    }
  }
}


.store_info_wrapper {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 2rem 0 4rem 0;

  p {
    margin: 0.8rem 0;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

//@media (min-width: 1400px) {
//  .container {
//    max-width: 1320px;
//  }
//}