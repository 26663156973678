@media (max-width: 880px) {

  .first_page_wrapper {
    .subtitle {
      h1 {
        font-size: 35px !important;
      }
    }

    .sns_button_list_wrapper {
      justify-content: space-around !important;

      button {
        width: 30% !important;
        flex: 0 0 30%;
        margin-left: 0;
        max-width: 80px;
        max-height: 80px;

        img {
          width: 70% !important;
        }
      }
    }

    .store_time_box {
      h2 {
        font-size: 1.2rem;
      }
    }

    .address_box {
      h1 {
        font-size: 2rem;
      }
    }
  }

  .second_page_wrapper {
    padding: 16px;


    .introduce_cat_list_wrapper {
      .cat_introduce_box {
        width: 40% !important;
        flex: 0 0 40% !important;

        p {
          font-size: 24px !important;
        }
      }
    }
  }

}